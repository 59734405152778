<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <input
            type="text"
            v-model="searchParams.search"
            v-on:keyup.enter="handleSearch"
            class="form-control form-control-solid w-250px me-2"
            :placeholder="constants.customers.SEARCH_CUSTOMER_DETAILS"
          />
        </div>
        <el-tooltip class="box-item" effect="dark" :content="constants.general.SEARCH" placement="top">
          <button type="button" @click.prevent="handleSearch" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
        </el-tooltip>
        <el-tooltip class="box-item" effect="dark" :content="constants.general.RESET" placement="top">
          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
        </el-tooltip>
      </div>
      <div class="card-toolbar">
        <button type="button" class="btn btn-primary" v-if="permission.isAddAllowed" @click="$router.push({ name: 'add-customer' })"><i class="bi bi-plus-circle-fill fs-4"></i>
          {{ constants.general.ADD }}
        </button>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :enable-items-per-page-dropdown="true"
        ref="updateProps"
      >
        <template v-slot:cell-name="{ row: data }">
          {{ data.name }}
        </template>
        <template v-slot:cell-email="{ row: data }">
          <a :href="`mailto:${data.email}`" class="text-gray-600 text-hover-primary mb-1">{{data.email}}</a>
        </template>
        <template v-slot:cell-roles="{ row: data }">
          <div v-if="data.roles.length">
            <span v-for="role in data.roles" :key="role">
              <span class="badge badge-light-primary"> {{ role }} </span>
            </span>
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <div v-if="permission.isEditAllowed || permission.isDeleteAllowed ">
            <el-tooltip class="box-item" effect="dark" v-if="permission.isEditAllowed" :content="constants.general.EDIT" placement="top">
              <button type="button" @click.prevent="$router.push({ name: 'edit-customer', params: { id: data.id } })" class="btn btn-icon btn-light btn-sm me-2">
                <i class="bi bi-pencil-square"></i>
              </button>
            </el-tooltip>
            <el-tooltip class="box-item" effect="dark" v-if="permission.isDeleteAllowed && loggedInUserId != data.id && data.is_deletable != 1" :content="constants.general.DELETE" placement="top">
              <button type="button" @click.prevent="remove(data.id, tableData.findIndex((i) => i.id === data.id))" class="btn btn-icon btn-light btn-sm">
                <i class="bi bi-trash text-danger"></i>
              </button>
            </el-tooltip>
          </div>
          <div v-else>
              --
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive } from 'vue';
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatDateTime } from "@/core/helpers/common";
import { notificationFire } from "@/composable/notification.js";
import { ElMessageBox } from 'element-plus';
import { useStore } from "vuex";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { useRoute } from "vue-router";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { Actions } from "@/store/enums/RememberSearchEnums";

export default {
  name: "customers-listing",
  components: {
    Datatable,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const total = ref(0);
    const tableData = ref([]);
    const initCustomers = ref([]);
    const loading = ref(false);
    const updateProps = ref(null);
    const constants = globalConstant;
    const noDataFound = ref(constants.general.LOADINIG);
    const loggedInUserId = ref(0);
    const searchParams = reactive({
      search: store.getters.getSearch(moduleName.value),
      user_type: 2,
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value)
    });
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
      isDeleteAllowed: false,
    })
    const tableHeader = ref([
      {
        name: constants.general.SRNO,
        key: "index",
        sortable: false,
        width: "w-10px",
      },
      {
        name: constants.customers.NAME,
        key: "name",
        sortable: true,
        width: "w-100px",
      },
      {
        name: constants.customers.EMAIL,
        key: "email",
        width: "w-200px",
        sortable: false,
      },
      {
        name: constants.customers.ROLES,
        key: "roles",
        width: "w-200px",
        sortable: false,
      },
      {
        name: constants.general.ACTIONS,
        key: "actions",
        width: "w-200px",
        sortable: false,
      },
    ]);

    const handleSearch = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (searchParams.search !== "") {
        let results = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], searchParams.search)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
        if (results.length == 0) {
          updateProps.value.updateProps(0, searchParams.recordsPerPage, searchParams.pageNub, constants.general.NO_DATA_FOUND);
        }
      }
      store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
      total.value = tableData.value.length;
      setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
    }

    const searchingFunc = (obj, value) => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toUpperCase().indexOf(value.toUpperCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    //Reset search value
    const resetSearch = () => {
      if(searchParams.search.length > 0){
        searchParams.search = '';
        handleSearch();
      }
    }

    //Get all customers
    const getCustomers = async () => {
      loading.value = true;
      await ApiService.query("users", { params: searchParams })
      .then(({ data }) => {
        if (data.data && data.data.length) {
          tableData.value.splice(0, tableData.value.length, ...data.data);
          initCustomers.value.splice(0, tableData.value.length, ...data.data);
          total.value = data.data.length;
          handleSearch();
        } else {
          updateProps.value.updateProps(0, searchParams.recordsPerPage, searchParams.pageNub, constants.general.NO_DATA_FOUND);
        }
        loading.value = false;
        setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
      })
      .catch((error) => {
        let response = error.response;
        let message = response.data.message;
        tableData.value = [];
        total.value = 0;
        loading.value = false;
        notificationFire(message,'error');
      });
    }

    //Delete the customer
    const remove = async (userId, index) => {
      ElMessageBox.confirm(
        constants.general.DELETE_MESSAGE,
        constants.general.ALERT_WARNING,
        {
          confirmButtonText: constants.general.ALERT_CONFIMATION,
          cancelButtonText: constants.general.ALERT_CANCEL,
          type: 'warning',
          center: true
        }
      )
      .then(() => {
        ApiService.delete("users/"+userId)
        .then((response) => {
          let status = response.status;
          let message = response.data.message;
          if (status == 200) {
            tableData.value.splice(index, 1);
            notificationFire(message, "success");
            total.value = total.value > 0  ? total.value - 1 : 0;
            setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
            if(tableData.value.length == 0){
              updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, constants.general.NO_DATA_FOUND);
            }
          }
        })
        .catch((error) => {
          notificationFire(error, "error");
        });
      })
    }

    //Set breadcrumbs and get the orders
    onMounted(async () => {
      const userInfo = JSON.parse(window.localStorage.getItem('user_info') || "{}");
      loggedInUserId.value = userInfo['id'];
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("createCustomer") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updateCustomer") || role == "Super Admin")
        permission.isEditAllowed = true;
      if (per.includes("deleteCustomer") || role == "Super Admin")
        permission.isDeleteAllowed = true;
      await getCustomers();
    })

    return {
      tableData,
      total,
      loading,
      formatDateTime,
      handleSearch,
      remove,
      resetSearch,
      searchParams,
      tableHeader,
      noDataFound,
      updateProps,
      constants,
      permission,
      loggedInUserId,
      moduleName
    }
  }
}
</script>